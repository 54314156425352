<template>
  <div>
    <div v-if="carregando == true" class="carregando">
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <Topo titulo="Forma de Pagamento" @OpenMenuLateral="menuOpen = true"></Topo>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <MenuRodape
      @OpenMenuLateral="menuOpen = true"
      :decoded="decoded"
    ></MenuRodape>
    <div class="container p-3 mb-5">
      <b-col>
        <h5 class="m-4 h2 text-center">Veículos</h5>
      </b-col>
      <div v-if="veiculos.length == 0" class="alert alert-danger">
        Nenhum Veículo Encontrado
      </div>
      <!-- INICIO LISTA DE VEICULOS -->
      <b-list-group v-for="(veiculo, index) in veiculos" :key="index">
        <div class="card-header bg-info text-white">
          <b-badge variant="primary">{{ veiculo.placa }}</b-badge> -
          {{ veiculo.descricao_modelo }}
        </div>
        <b-card header-bg-variant="dark">
          <!-- INCIO METODOS PAGAMENTOS -->
          <b-list-group
            v-for="(forma, index2) in formasPagementos"
            :key="index2"
          >
            <b-list-group-item
              action
              :variant="definiVariant(veiculo, forma)"
              @click="
                toggleCollapseExterno(forma, index),
                  selecionaForma(veiculo, forma)
              "
              aria-controls="cartao"
            >
              <h4 class="mb-3">{{ forma.nomePagamento }}</h4>
              <!-- INICIO CALLAPSU EXTERNO -->
              <b-collapse
                v-if="verificaSeCartao(forma)"
                id="cartao"
                v-model="veiculo.collapseExternoVisible"
              >
                <b-row
                  class="mb-3"
                  v-if="Object.keys(cartaoPrincipalSelecionado).length"
                >
                  <b-col cols="6">
                    <b-badge variant="danger" style="font-size: 1.2em">
                      {{
                        cartaoPrincipalSelecionado.brand.toUpperCase()
                      }}</b-badge
                    ></b-col
                  >
                  <b-col cols="6">
                    <b-badge variant="dark" style="font-size: 1.2em">{{
                      cartaoPrincipalSelecionado.numeroCartao
                    }}</b-badge></b-col
                  >
                </b-row>
                <b-row>
                  <!-- INICIO BOTÃO TROCA CARTÃO -->
                  <b-col cols="6" class="d-flex justify-content-center">
                    <div @click.stop>
                      <b-button
                        size="sm"
                        :variant="iconeCallapseInterno1(index).variant"
                        v-b-toggle.trocarCartao
                        aria-controls="trocarCartao"
                        @click="toggleCollapseInterno1(index)"
                      >
                        <i
                          :class="`fa fa-fw ${
                            iconeCallapseInterno1(index).icone
                          }`"
                        ></i>
                      </b-button>
                    </div>
                  </b-col>
                  <!-- FIM BOTÃO TROCA CARTAO -->
                  <!-- INICIO BOTÃO ADD CARTÃO -->
                  <b-col cols="6" class="d-flex justify-content-center">
                    <div @click.stop>
                      <b-button
                        size="sm"
                        :variant="iconeCallapseInterno2(index).variant"
                        v-b-toggle.addCartao
                        aria-controls="addCartao"
                        @click="toggleCollapseInterno2(index)"
                      >
                        <i
                          :class="`fa fa-fw ${
                            iconeCallapseInterno2(index).icone
                          }`"
                        ></i>
                      </b-button>
                    </div>
                  </b-col>
                  <!-- FIM BOTÃO TROCA CARTAO -->
                </b-row>
                <!-- INICIO CALLAPSU INTERNO 1 - TROCAR CARTÃO -->
                <b-collapse
                  v-model="veiculo.collapseInterno1Visible"
                  id="collapseInterno1-trocarCartao"
                  title="Trocar Cartão"
                >
                  <b-form-group
                    label="Selecione o cartão da cobrança"
                    v-slot="{ ariaDescribedby }"
                    class="d-flex justify-content-center mt-4"
                  >
                    <b-form-radio-group
                      name="Cartao Principal"
                      v-model="cartaoPrincipalSelecionado"
                      buttons
                      button-variant="light"
                      stacked
                    >
                      <b-form-radio
                        class="mt-3 d-flex align-items-center justify-content-between"
                        v-for="(item, index) in cartoesAssociado"
                        :key="index"
                        :value="item"
                        ><div
                          class="d-flex justify-content-between"
                          style="width: 100%"
                        >
                          <b-badge variant="danger" class="mr-4">{{
                            item.brand
                          }}</b-badge>
                          <b-badge variant="dark">{{
                            item.numeroCartao
                          }}</b-badge>
                        </div>
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-collapse>
                <!--  FIM CALLAPSU INTERNO 1 - TROCAR CARTÃO -->
                <!--  INICIO CALLAPSU INTERNO 2 - ADD CARTÃO -->
                <b-collapse
                  v-model="veiculo.collapseInterno2Visible"
                  id="collapseInterno1-addCartao"
                  title="Adicionar Cartão"
                  centered
                >
                  <b-container fluid class="mt-4">
                    <b-row>
                      <b-col><label for="Nome">Nome</label></b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        ><b-form-input
                          v-model="novoCartao.name"
                          type="text"
                          id="Nome"
                          name="Nome"
                          placeholder="Nome Completo"
                          v-validate="{ required: true }"
                          :state="validateCommun('S', 'Nome')"
                          :class="validateCommun('C', 'Nome')"
                          @click.stop
                        ></b-form-input></b-col
                    ></b-row>
                    <b-row>
                      <b-col class="mt-4"
                        ><label for="numeroCartao"
                          >Número do Cartao</label
                        ></b-col
                      >
                    </b-row>
                    <b-row>
                      <b-col
                        ><the-mask
                          v-model="novoCartao.cardNumber"
                          :masked="true"
                          mask="#### #### #### ####"
                          class="form-control"
                          type="text"
                          id="numeroCartao"
                          name="Número do Cartão"
                          placeholder="0000 0000 0000 0000"
                          v-validate="{ required: true, length: 19 }"
                          :state="validateCommun('S', 'Número do Cartão')"
                          :class="validateCommun('C', 'Número do Cartão')"
                          @click.stop
                        ></the-mask></b-col
                    ></b-row>
                    <b-row>
                      <b-col cols="6" class="mt-4"
                        ><label for="expiracao">Expiração (MM/AA)</label>
                        <the-mask
                          v-model="novoCartao.expiration"
                          :masked="true"
                          mask="##/##"
                          class="form-control"
                          type="text"
                          id="expiracao"
                          name="Data de Expiração"
                          placeholder="00-00"
                          v-validate="{ required: true, length: 5 }"
                          :state="validateCommun('S', 'Data de Expiração')"
                          :class="validateCommun('C', 'Data de Expiração')"
                          @click.stop
                        ></the-mask>
                      </b-col>
                      <b-col cols="6" class="mt-4"
                        ><label for="codigoVerificacao"
                          >Código de Segurança</label
                        ><the-mask
                          v-model="novoCartao.security"
                          :masked="true"
                          mask="###"
                          class="form-control"
                          type="text"
                          id="codigoVerificacao"
                          name="Código de Verificação"
                          placeholder="000"
                          v-validate="{ required: true, length: 3 }"
                          :state="validateCommun('S', 'Código de Verificação')"
                          :class="validateCommun('C', 'Código de Verificação')"
                          @click.stop
                        ></the-mask
                      ></b-col>
                    </b-row>
                    <b-row>
                      <b-col class="d-flex justify-content-center mt-4"
                        ><div @click.stop>
                          <b-button
                            variant="success"
                            @click="adicionarCartao(index)"
                            >Adicionar Cartão</b-button
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-collapse>
                <!-- FIM CALLAPSU INTERNO 1 - TROCAR CARTÃO -->
              </b-collapse>
              <!-- FIM CALLAPSU EXTERNO-->
            </b-list-group-item>
          </b-list-group>
          <!-- FIM METODOS PAGAMENTOS -->
        </b-card>
      </b-list-group>
      <!-- FIM LISTA DE VEICULOS -->
      <b-row>
        <b-col>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mt-4"
            :style="`background-color:${temaCor.botaoFormularios};`"
            @click="atualizaFormaPagamento"
            >salvar</b-button
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import colors from './../services/colors';
import jwt from 'jsonwebtoken';
import Topo from './TopoInterno';
import MenuRodape from './MenuRodape';
import MenuLateral from './MenuLateral';
import service from '../services';
import locale from './../locale';
import utils from '../services/utils.js';

export default {
  name: 'FormaPagamento',
  components: { Topo, MenuRodape, MenuLateral },
  data() {
    return {
      temaCor: null,
      decoded: {},
      formasPagementos: [],
      menuOpen: false,
      carregando: false,
      notify: utils.showToast,
      validateClass: utils.validateClass,
      validateState: utils.validateState,
      veiculos: [],
      cartoesAssociado: [],
      cartaoPrincipalSelecionado: {},
      novoCartao: {
        name: '',
        cardNumber: '',
        expiration: '',
        security: '',
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    getColors() {
      this.temaCor = colors.getColorsTema();
    },
    getDecoded() {
      const token = localStorage.getItem('user-token');
      if (token) this.decoded = jwt.decode(token);
    },
    toggleCollapseExterno(item, index) {
      const cartao = this.verificaSeCartao(item);

      // fecha o colapso externo caso seja selecionada um forma de pagamento que não é cartão
      if (!cartao) {
        return (this.veiculos[index].collapseExternoVisible = false);
      }

      // mantém o colapso externo aberto caso ele mesmo esteja aberto e algum interno também esteja aberto
      if (
        this.veiculos[index].collapseExternoVisible &&
        (this.veiculos[index].collapseInterno1Visible ||
          this.veiculos[index].collapseInterno2Visible)
      ) {
        this.veiculos[index].collapseExternoVisible = true;
      }
      // fecha o colapso externo caso ele mesmo estaja aberto e ambos os internos estejam fecahdos
      else if (
        this.veiculos[index].collapseExternoVisible &&
        !this.veiculos[index].collapseInterno1Visible &&
        !this.veiculos[index].collapseInterno2Visible
      ) {
        this.veiculos[index].collapseExternoVisible = false;
      }
      // abre o colapso externo
      else {
        this.veiculos[index].collapseExternoVisible =
          !this.veiculos[index].collapseExternoVisible;
      }
    },
    toggleCollapseInterno1(index) {
      this.veiculos[index].collapseInterno1Visible =
        !this.veiculos[index].collapseInterno1Visible;
    },
    toggleCollapseInterno2(index) {
      this.veiculos[index].collapseInterno2Visible =
        !this.veiculos[index].collapseInterno2Visible;
    },
    iconeCallapseInterno1(index) {
      const icone = this.veiculos[index].collapseInterno1Visible
        ? 'fa-times-circle'
        : 'fa-pencil-square-o';
      const variant = this.veiculos[index].collapseInterno1Visible
        ? 'danger'
        : 'primary';
      return { icone, variant };
    },
    iconeCallapseInterno2(index) {
      const icone = this.veiculos[index].collapseInterno2Visible
        ? 'fa-times-circle'
        : 'fa-plus';
      const variant = this.veiculos[index].collapseInterno2Visible
        ? 'danger'
        : 'success';
      return { icone, variant };
    },
    async buscaDados() {
      try {
        const { id_prospect } = this.decoded;
        this.carregando = true;
        const respostas = await Promise.all([
          service.post('Financeiro', 'buscaFormasPagamentoDisponiveisMensal', {
            idAssociado: id_prospect,
          }),
          service.post('Veiculos', 'listarVeiculosDashApp', {}),
          service.post('Associado', 'buscar-cartoes-associado', {
            idProspect: id_prospect,
          }),
        ]);

        const formasPagamentoValidas = this.removeFormaPagamentoBanidas(
          respostas[0].data,
        );

        const resFormasPagamento = formasPagamentoValidas;
        const resVeiculos = respostas[1].data;
        const resCartoes = respostas[2].data;

        if (resFormasPagamento.length) {
          this.formasPagementos = [];
          this.formasPagementos = resFormasPagamento.map((f) => {
            return {
              ...f,
              idTipoPagamento: f.id_tipoPagamentoEmpresa,
            };
          });
        }

        if (resVeiculos.veiculosAtivos.length) {
          this.veiculos = [];
          this.veiculos = resVeiculos.veiculosAtivos;
        }

        if (resCartoes.length) {
          this.cartoesAssociado = [];
          this.cartoesAssociado = resCartoes;
          this.cartaoPrincipal();
        }
      } catch (error) {
        this.notify(this.$bvToast, error, 'danger');
      } finally {
        this.carregando = false;
      }
    },
    definiVariant(veiculo, formaPagamento) {
      const definido =
        veiculo.id_TipoPagamentoMensal === formaPagamento.idTipoPagamento;
      return definido ? 'success' : 'light';
    },
    selecionaForma(veiculo, forma) {
      this.veiculos = this.veiculos.map((v) =>
        veiculo.id_veiculo === v.id_veiculo &&
        veiculo.id_TipoPagamentoMensal === v.id_TipoPagamentoMensal
          ? { ...v, id_TipoPagamentoMensal: forma.idTipoPagamento }
          : v,
      );
    },
    validateCommun(tipo, campo) {
      tipo === 'C' && this.validateClass(this.veeFields, this.veeErrors, campo);
      tipo === 'S' && this.validateState(this.veeFields, this.veeErrors, campo);
    },
    async validate() {
      const validado = await this.$validator.validateAll();
      this.$validator.errors
        .all()
        .map((error) => this.notify(this.$bvToast, error, 'danger'));
      return validado;
    },
    verificaSeCartao(item) {
      return item.nomePagamento.toLowerCase() === 'cartão de crédito';
    },
    cartaoPrincipal() {
      const cartaoPrincipal = this.cartoesAssociado.find(
        (cartao) => cartao.principal,
      );
      if (cartaoPrincipal) {
        this.cartaoPrincipalSelecionado = cartaoPrincipal;
        return cartaoPrincipal;
      }
    },
    async adicionarCartao() {
      try {
        const validado = await this.validate();
        if (validado) {
          this.carregando = true;

          const { id_prospect } = this.decoded;
          const body = {
            idProspect: id_prospect,
            ...this.novoCartao,
          };

          const resposta = await service.post(
            'Associado',
            'AdicionarCartao',
            body,
          );

          const { myId } = resposta.data;
          await this.atualizarCartaoAssociadoPrincipal(myId, id_prospect);

          await this.atualizaFormaPagamento();

          await this.buscaDados();

          (this.novoCartao = {
            name: '',
            cardNumber: '',
            expiration: '',
            security: '',
          }),
            this.toggleCollapseInterno2();
        }
      } catch (error) {
        this.notify(this.$bvToast, error, 'danger');
      } finally {
        this.carregando = false;
      }
    },
    async atualizaFormaPagamento() {
      try {
        this.carregando = true;
        await Promise.all(
          this.veiculos.map((veiculo) => {
            const { id_TipoPagamentoMensal, id_veiculo } = veiculo;

            const validaCartao = this.veriricaCartaoPrincipalSelecionado(
              id_TipoPagamentoMensal,
            );

            if (typeof validaCartao === 'string') {
              throw validaCartao;
            }

            return service.post('Veiculos', `atualizarFormaPagamento`, {
              id_veiculo,
              idTipoPagamentoMensal: id_TipoPagamentoMensal,
              idProspect: this.decoded.id_prospect,
            });
          }),

          this.veiculos.map((veiculo) => {
            const { id_TipoPagamentoMensal } = veiculo;
            const formaPagamento = this.formasPagementos.find(
              (f) => f.idTipoPagamento === id_TipoPagamentoMensal,
            );
            const cartao = this.verificaSeCartao(formaPagamento);

            if (cartao) {
              const { idAssociadoCartao } = this.cartaoPrincipalSelecionado;
              const { id_prospect } = this.decoded;

              return this.atualizarCartaoAssociadoPrincipal(
                idAssociadoCartao,
                id_prospect,
              );
            } else {
              return Promise.resolve();
            }
          }),
        ),
          this.notify(
            this.$bvToast,
            'Forma de pagamento atualizada com sucesso.',
            'success',
          );
      } catch (error) {
        this.notify(this.$bvToast, error, 'danger');
      } finally {
        this.carregando = false;
      }
    },
    veriricaCartaoPrincipalSelecionado(idPagamento) {
      const formaPagamento = this.formasPagementos.find(
        (f) => f.idTipoPagamento === idPagamento,
      );
      const cartao = this.verificaSeCartao(formaPagamento);

      if (cartao && !Object.keys(this.cartaoPrincipalSelecionado).length) {
        const error =
          'Adicione um cartão ou selecione outra forma de pagamento.';
        return error;
      } else {
        return true;
      }
    },
    async atualizarCartaoAssociadoPrincipal(idAssociadoCartao, idProspect) {
      try {
        await service.post('Associado', 'AtualizarCartaoAssociadoPrincipal', {
          idAssociadoCartao,
          idProspect,
        });
      } catch (error) {
        this.notify(this.$bvToast, error, 'danger');
      }
    },
    removeFormaPagamentoBanidas(formasPagamento) {
      const formasPagemntoBanidas = ['dinheiro', 'cartão de crédito', 'pix'];
      return formasPagamento.filter(
        (f) => !formasPagemntoBanidas.includes(f.nomePagamento.toLowerCase()),
      );
    },
  },
  created() {
    this.getColors();
    this.getDecoded();
  },
  mounted() {
    this.buscaDados();
    this.$validator.localize('en', locale);
  },
};
</script>
<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}
.container-botoes {
  display: flex;
  gap: 1em;
}
</style>
